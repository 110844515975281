/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import Zoom from 'react-medium-image-zoom';
// import Modal from 'react-modal';
import ScrollAnimation from 'react-animate-on-scroll';
import Navigation from '../components/Nav';
import NavigationEn from '../components/Nav.en';
import Footer from '../components/Footer';

import 'react-medium-image-zoom/dist/styles.css';
import '../style/app.scss';

// Modal.setAppElement('#___gatsby');
const encode = (data) =>
	Object.keys(data)
		.map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
		.join('&');

// const customStyles = {
// 	content: {
// 		top: '50%',
// 		left: '50%',
// 		right: 'auto',
// 		bottom: 'auto',
// 		padding: '26px',
// 		marginRight: 0,
// 		borderRadius: '0',
// 		maxWidth: '90%',
// 		width: '100%',
// 		background: 'rgba(255,255,255, 0.9)',
// 		border: 'none',
// 		transform: 'translate(-50%, -50%)',
// 	},
// 	overlay: {
// 		backgroundColor: 'rgba(255,255,255, 0.9)',
// 		zIndex: 3,
// 	},
// };
export const BlogPostTemplateEn = ({
	content,
	passedTags,
	title,
	acf,
	featuredImage,
	mainContent,
	secContent,
	featuredContent,
}) => {
	// const [modalIsOpen, setIsOpen] = useState(false);
	const [popIsOpen, setOpenPop] = useState(false);
	const [activeForm, setActiveForm] = useState(false);
	const [activeCm, setActiveCm] = useState(true);
	const [openedBox, setOpenedBox] = useState('');
	// modal pass data
	const [elementIdPassed, setElementIdPassed] = useState('');
	const [elementNamePassed, setElementNamePassed] = useState('');
	// form elements state
	const [submited, setSubmit] = useState(false);
	const [name, setName] = useState('');
	const [lastname, setLastname] = useState('');
	const [email, setEmail] = useState('');
	const [subject, setSubject] = useState('');
	const [message, setMessage] = useState('');
	const [productName, setProductName] = useState('');

	function submitElementFormEn(e) {
		fetch('/', {
			method: 'POST',
			headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
			body: encode({ 'form-name': 'product-inquiry', name, lastname, email, subject, message }),
		})
			.then(() => setSubmit(true))
			.catch((error) => alert(error));

		e.preventDefault();
	}
	function showMoreContent(e) {
		const btnParentElement = e.target.closest('.readMoreParent');
		btnParentElement.classList.add('showContent');
	}

	function toggleBox(boxId, nameElement) {
		if (openedBox === boxId) {
			setOpenedBox('');
			setElementIdPassed('');
			setElementNamePassed('');
		} else {
			setOpenedBox(boxId);
			setElementIdPassed(boxId);
			setElementNamePassed(nameElement);
		}
	}

	function openPop(formId, nameElement) {
		setOpenPop(true);
		setActiveForm(formId);
		setElementIdPassed(formId);
		setElementNamePassed(nameElement);
		setProductName(`Product Id: ${formId}, Product Name: ${nameElement}`);
	}
	function closePopup() {
		setOpenPop(false);
		setActiveForm(false);
		setElementIdPassed(false);
		setElementIdPassed(false);
		setElementNamePassed(false);
	}

	// function openModal(formId, nameElement) {
	// 	setIsOpen(true);
	// 	setActiveForm(formId);
	// 	setElementIdPassed(formId);
	// 	setElementNamePassed(nameElement);
	// 	setProductName(`Product ID: ${formId}, Product Name: ${nameElement}`);
	// }
	// function closeModal() {
	// 	setIsOpen(false);
	// 	setActiveForm(false);
	// }
	return (
		<>
			<section className="section">
				<ScrollAnimation animateIn="fadeIn" animateOnce>
					{' '}
					<div className="imageVideoHolder">
						{acf.youtubeVideoId ? (
							<>
								<div className="videoImage">
									<iframe
										// src={`https://www.youtube.com/embed/${activeVideo}`}
										src={`https://player.vimeo.com/video/${acf.youtubeVideoId}?background=1&autoplay=1&loop=1&title=0&byline=0&portrait=0`}
										frameBorder="0"
										webkitallowfullscreen=""
										mozallowfullscreen=""
										allowFullScreen=""
										loop="1"
										autoPlay="1"
										muted="1"
										width="100%"
										height="75%"
										title={`Vimeo Video ${acf.youtubeVideoId}`}
									/>
									{/* <img
										// src={`http://i3.ytimg.com/vi/${acf.youtubeVideoId}/maxresdefault.jpg`}
										src={featuredImage.localFile.childImageSharp.fluid.src}
										alt=""
										className="placeholder"
										onClick={() => openModal(acf.youtubeVideoId)}
									/> */}
									{/* <img src={IconPlay} className="play_video" alt="" /> */}
								</div>
							</>
						) : (
							<div className="featured_image" style={{ display: 'block', width: '100%', height: '100%' }}>
								<Img
									// imgStyle={{ height: 600, objectPosition: 'center' }}
									style={{ height: '100%' }}
									imgStyle={{ objectFit: 'cover', objectPosition: 'center', width: '100%' }}
									fluid={featuredImage.localFile.childImageSharp.fluid}
								/>
							</div>
							// <Img
							// 	imgStyle={{ objectPosition: 'center' }}
							// 	fluid={featuredImage.localFile.childImageSharp.fluid}
							// />
						)}
					</div>
				</ScrollAnimation>
				<div className="content">
					<ScrollAnimation animateIn="fadeIn" animateOnce>
						<div className="tagsWrapper">
							<div className="tags">
								{passedTags
									? passedTags.map((singleTag, stk) => (
											<span key={stk}>
												<a className="tagLink" href={`/tag/${singleTag.slug}/`}>
													#{singleTag.name}
												</a>
											</span>
									  ))
									: ''}
							</div>
						</div>
					</ScrollAnimation>
					<ScrollAnimation animateIn="fadeIn" animateOnce>
						<div className="titleWrapper">
							<h1 className="title">{title}</h1>
						</div>
					</ScrollAnimation>

					<div className="container fullWidth">
						<ScrollAnimation animateIn="fadeIn" animateOnce>
							<div className="defaultContent" dangerouslySetInnerHTML={{ __html: content }} />
						</ScrollAnimation>
					</div>
					<div className="container fullWidth">
						<div className="mainAcfContent">
							{mainContent.map((mainBlock, j) => (
								<div key={j} className={`${j % 2 ? 'even' : 'odd'} mainAcfContentWrapper`}>
									<ScrollAnimation animateIn="fadeIn" animateOnce>
										<div className=" mainAcfContents readMoreParent">
											<div dangerouslySetInnerHTML={{ __html: mainBlock.contentBlock }} />
											<button type="button" className="readMoreBtn" onClick={showMoreContent}>
												Read more
											</button>
										</div>
									</ScrollAnimation>
									<div className="images mainAcfImages">
										{mainBlock.imageBlock?.map((mainImage, i) => (
											<ScrollAnimation key={i} animateIn="fadeIn">
												<Img key={i} fluid={mainImage.localFile.childImageSharp.fluid} />
											</ScrollAnimation>
										))}
									</div>
								</div>
							))}
						</div>
						<div className="secondaryAcfContent">
							{secContent?.map((block, k) => (
								<div key={k} className="secondaryAcfContents">
									<div className="secondaryContentWrap">
										<ScrollAnimation animateIn="fadeIn" animateOnce className="readMoreParent">
											<div
												className="secondaryContent"
												dangerouslySetInnerHTML={{
													__html: block.secondaryContentText,
												}}
											/>
											{block.secondaryContentText !== '' ? (
												<button type="button" className="readMoreBtn" onClick={showMoreContent}>
													read more
												</button>
											) : (
												''
											)}
										</ScrollAnimation>
									</div>
									<div className="images secondaryAcfImages">
										<div className="imgwrap">
											{block.secondaryContentImages?.map((imageSecond, z) => (
												<ScrollAnimation key={z} animateIn="fadeIn">
													<Img
														className="secondaryImg"
														imgStyle={{ objectPosition: 'center' }}
														fluid={imageSecond.localFile.childImageSharp.fluid}
														// fluid={imageSecond.localFile.childImageSharp.fluid}
													/>
												</ScrollAnimation>
											))}
										</div>
									</div>
								</div>
							))}
						</div>
						<div className="featuredCont">
							<div className="featTitle">
								<h3>FEATURED IN THIS ROOM</h3>
							</div>
							<div className="featuredElementsContent">
								{featuredContent.map((element, n) => (
									<div key={n} className="featuredContentWrapper">
										<div
											className={`elementImage ${
												openedBox === element.elementId ? 'is_active' : ''
											} `}
											onClick={() => {
												toggleBox(element.elementId, element.elementName);
											}}
										>
											<ScrollAnimation animateIn="fadeIn" animateOnce>
												<Zoom>
													<img
														alt={`element ${element.elementName}`}
														src={element.elementImage.localFile.childImageSharp.fluid.src}
														width="100%"
													/>
													<div className={`selectedBox ${openedBox ? 'is_active' : ''} `} />
												</Zoom>
											</ScrollAnimation>
										</div>
										<ScrollAnimation animateIn="fadeIn" animateOnce>
											<div className="elementDefaultData">
												<div className="elementId">{element.elementId}</div>
												<div className="elementName">{element.elementName}</div>
											</div>
										</ScrollAnimation>
										<div
											className={`showFeaturedContent ${
												openedBox === element.elementId ? 'is_active' : ''
											} `}
										>
											<div className="elementTagline">{element.elementTagline}</div>
											<div className="dimToggle">
												<button
													type="button"
													className={`cmBtn toggleDimension ${activeCm ? 'is_active' : ''} `}
													onClick={() => setActiveCm(true)}
												>
													CM
												</button>
												<span>|</span>
												<button
													type="button"
													className={`inBtn toggleDimension ${activeCm ? '' : 'is_active'} `}
													onClick={() => setActiveCm(false)}
												>
													INCH
												</button>
												<div className="elementdimensions cmDim">
													{activeCm ? element.cmDimensions : element.inchDimensions}
												</div>
											</div>
											<div className="formButton">
												<button
													className="openFormModal"
													type="button"
													// onClick={() => openModal(element.elementId, element.elementName)}
													onClick={() => openPop(element.elementId, element.elementName)}
												>
													Request Inquiry
												</button>
											</div>
										</div>
									</div>
								))}
							</div>
						</div>
					</div>
				</div>
			</section>
			<div
				//  className="formInq
				className={`formInq ${popIsOpen === true ? 'is_active' : ''} `}
			>
				{/* onClick={closeModal}  */}
				<button onClick={() => closePopup()} type="button" className="close_modal">
					X
				</button>
				<div className="element_form_container">
					{activeForm ? (
						<div className="formWrapper formElementWrapper">
							<div className="titleWrapper">
								<h3 className="title">Inquiry</h3>
								<h4 className="title">{productName}</h4>
							</div>
							<form
								className="elementInquiryForm"
								name="product-inquiry"
								method="POST"
								data-netlify="true"
								data-netlify-honeypot="bot-field"
								// action=""
								onSubmit={submitElementFormEn}
							>
								<label htmlFor="form-name">
									<input
										type="hidden"
										name="form-name"
										value="product-inquiry"
										aria-label="form-name"
									/>
								</label>
								<p className="hidden">
									<label htmlFor="bot-field" className="hiddenField">
										Don’t fill this out if you’re human: <input name="bot-field" />
									</label>
								</p>
								{/* <label htmlFor="productName">
									<input
										type="hidden"
										name="productName"
										value={productName}
										aria-label="productName"
									/>
								</label> */}
								<label htmlFor="name" className="half_width">
									<input
										type="text"
										placeholder="Name *"
										name="name"
										aria-label="Name"
										value={name}
										required
										onChange={(e) => setName(e.target.value)}
									/>
								</label>
								<label htmlFor="lastname" className="half_width">
									<input
										type="text"
										placeholder="Lastname *"
										name="lastname"
										aria-label="Last Name"
										value={lastname}
										required
										onChange={(e) => setLastname(e.target.value)}
									/>
								</label>
								<label htmlFor="email">
									<input
										type="email"
										placeholder="Email *"
										aria-label="email"
										name="email"
										value={email}
										required
										onChange={(e) => setEmail(e.target.value)}
									/>
								</label>
								<label htmlFor="subject">
									<input
										type="subject"
										placeholder="Subject *"
										aria-label="subject"
										name="subject"
										value={subject}
										required
										onChange={(e) => setSubject(e.target.value)}
									/>
								</label>
								<label htmlFor="message">
									<textarea
										placeholder="Message *"
										aria-label="message"
										name="message"
										rows="8"
										value={message}
										required
										onChange={(e) => setMessage(e.target.value)}
									/>
								</label>
								<label htmlFor="submit" className="submitBtnWrapper">
									<button className="submitButton" type="submit" aria-label="submit">
										SEND
									</button>
									<p className={`${submited ? 'submited' : ''}`}>
										Thank you! Your inquiry has been submitted!
									</p>
								</label>
							</form>
						</div>
					) : (
						''
					)}
				</div>
			</div>
			{/* <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={customStyles}>
				<button onClick={closeModal} type="button" className="close_modal">
					X
				</button>
				<div className="element_form_container">
					{activeForm ? (
						<div className="formWrapper formElementWrapper">
							<div className="titleWrapper">
								<h3 className="title">Inquiry</h3>
								<h4 className="title">{productName}</h4>
							</div>
							<form
								className="elementInquiryForm"
								name="product-inquiry"
								method="POST"
								data-netlify="true"
								data-netlify-honeypot="bot-field"
								// action=""
								onSubmit={submitElementFormEn}
							>
								<label htmlFor="form-name">
									<input
										type="hidden"
										name="form-name"
										value="product-inquiry"
										aria-label="form-name"
									/>
								</label>
								<p className="hidden">
									<label htmlFor="bot-field" className="hiddenField">
										Don’t fill this out if you’re human: <input name="bot-field" />
									</label>
								</p>
								{/* <label htmlFor="productName">
									<input
										type="hidden"
										name="productName"
										value={productName}
										aria-label="productName"
									/>
								</label> //here was orig closed with commentEnd

								<label htmlFor="name" className="half_width">
									<input
										type="text"
										placeholder="Name *"
										name="name"
										aria-label="Name"
										value={name}
										required
										onChange={(e) => setName(e.target.value)}
									/>
								</label>
								<label htmlFor="lastname" className="half_width">
									<input
										type="text"
										placeholder="Lastname *"
										name="lastname"
										aria-label="Last Name"
										value={lastname}
										required
										onChange={(e) => setLastname(e.target.value)}
									/>
								</label>
								<label htmlFor="email">
									<input
										type="email"
										placeholder="Email *"
										aria-label="email"
										name="email"
										value={email}
										required
										onChange={(e) => setEmail(e.target.value)}
									/>
								</label>
								<label htmlFor="subject">
									<input
										type="subject"
										placeholder="Subject *"
										aria-label="subject"
										name="subject"
										value={subject}
										required
										onChange={(e) => setSubject(e.target.value)}
									/>
								</label>
								<label htmlFor="message">
									<textarea
										placeholder="Message *"
										aria-label="message"
										name="message"
										rows="8"
										value={message}
										required
										onChange={(e) => setMessage(e.target.value)}
									/>
								</label>
								<label htmlFor="submit" className="submitBtnWrapper">
									<button className="submitButton" type="submit" aria-label="submit">
										SEND
									</button>
									<p className={`${submited ? 'submited' : ''}`}>
										Thank you! Your inquiry has been submitted!
									</p>
								</label>
							</form>
						</div>
					) : (
						''
					)}
				</div>
			</Modal> */}
		</>
	);
};

BlogPostTemplateEn.propTypes = {
	content: PropTypes.node.isRequired,
	title: PropTypes.string,
	acf: PropTypes.object,
	featuredImage: PropTypes.object,
	mainContent: PropTypes.array,
	secContent: PropTypes.array,
	featuredContent: PropTypes.array,
	passedTags: PropTypes.array,
};

const BlogPostEn = ({ data, pageContext }) => {
	const { wordpressPost: post } = data;

	return (
		<div className="postWrapper">
			<Helmet title={`${post.title} | Blog`} />
			{pageContext.locale === 'en_US' ? <NavigationEn /> : <Navigation />}
			<div className="postContent">
				<BlogPostTemplateEn
					passedTags={post.categories ? post.categories : null}
					title={post.title}
					content={post.content}
					acf={post.acf}
					featuredImage={post.featured_media}
					mainContent={post.acf.mainContentRepeater}
					secContent={post.acf.have_secondary_content ? post.acf.secondary_content : null}
					featuredContent={post.acf.featuredElements}
					// tags={post.tags}
				/>
			</div>
			<Footer />
		</div>
	);
};

BlogPostEn.propTypes = {
	data: PropTypes.object.isRequired,
	pageContext: PropTypes.object.isRequired,
};

export default BlogPostEn;

export const pageQuery = graphql`
	query($id: String) {
		wordpressPost(id: { eq: $id }) {
			acf {
				mainContentRepeater {
					contentBlock
					imageBlock {
						localFile {
							childImageSharp {
								fixed {
									...GatsbyImageSharpFixed
								}
								fluid(maxWidth: 1920) {
									...GatsbyImageSharpFluid
								}
							}
						}
					}
				}
				have_secondary_content
				secondary_content {
					secondaryContentText
					secondaryContentImages {
						localFile {
							childImageSharp {
								fixed {
									...GatsbyImageSharpFixed
								}
								fluid(maxWidth: 1920) {
									...GatsbyImageSharpFluid
								}
							}
						}
					}
				}
				imageOfVideo {
					id
					localFile {
						childImageSharp {
							fixed {
								...GatsbyImageSharpFixed
							}
							fluid(maxWidth: 1920) {
								...GatsbyImageSharpFluid
							}
						}
					}
				}
				featuredElements {
					cmDimensions
					elementId
					elementName
					elementTagline
					inchDimensions
					elementImage {
						localFile {
							childImageSharp {
								fixed {
									...GatsbyImageSharpFixed
								}
								fluid {
									...GatsbyImageSharpFluid
								}
							}
						}
						wpml_translations {
							locale
							wordpress_id
							post_title
							href
						}
						title
						slug
					}
				}
				youtubeVideoId
			}
			featured_media {
				localFile {
					childImageSharp {
						fixed {
							...GatsbyImageSharpFixed
						}
						fluid(maxWidth: 1920) {
							...GatsbyImageSharpFluid
						}
					}
				}
			}
			wpml_translations {
				href
				locale
				post_title
				wordpress_id
			}
			categories {
				id
				name
				slug
				wordpress_id
			}
			content
			wordpress_id
			wpml_current_locale
			title
			slug
			id
		}
	}
`;
